<template>
  <div>
    <top />
    <section id="home">
      <div class="container">
        <div class="row row_home">
          <div class="col-md-7 conteudo">
            <h1 class="title_home">
              Economize
              <span class="tp_recurso"> tempo e recursos </span>centralizando
              todas as suas lojas online em um só lugar
            </h1>
            <p class="text_home">
              Com as nossas soluções em gestão e integração de ecommerces, você
              pode gerenciar inventário, preços, pedidos e remessas em tempo
              real em todas as suas lojas online com o suporte de uma equipe
              especializada.
            </p>
            <div class="div_btn">
              <!---<button type="button" class="btn_cmc_agr">
                <span class="text_btn_agora">Comece agora</span>
              </button>-->
              <a href="/#faca_parte"
                ><button type="button" class="btn_vantagens">
                  <span class="text_btn_vantagens"
                    >Conheça todas as vantagens</span
                  >
                </button></a
              >
            </div>
          </div>
          <div class="col-md-5 img_div">
            <img class="img_cardboard" src="../assets/img/men_cardboard.png" />
            <img
              class="cash"
              src="../assets/img/icon_home/cash.png"
              style="position: absolute"
            />
            <img
              class="cart1"
              src="../assets/img/icon_home/cart1.png"
              style="position: absolute"
            />
            <img
              class="bag"
              src="../assets/img/icon_home/bag.png"
              style="position: absolute"
            />
            <img
              class="paper"
              src="../assets/img/icon_home/paper.png"
              style="position: absolute"
            />
            <img
              class="cart2"
              src="../assets/img/icon_home/cart2.png"
              style="position: absolute"
            />
          </div>
        </div>
      </div>
    </section>
    <section id="faca_parte">
      <div class="container">
        <h1 class="title_faca_parte">Faça parte da Smart Sellers</h1>
        <p class="descri_faca_parte">
          Somos uma equipe dedicada a oferecer soluções criativas para ajudar
          nossos clientes a crescer seus negócios. Nós oferecemos uma variedade
          de serviços que incluem:
        </p>
        <div class="row">
          <div class="col-md-4 div_ads">
            <img
              class="img_ads"
              src="../assets/img/icon_faca_parte/img_ads.png"
            />
            <h5 class="h5_title">Criativo de anúncios</h5>
            <p class="descricao_img">
              Nosso objetivo é criar anúncios criativos, eficazes e persuasivos,
              com descritivos envolventes que aumentam a conversão e ajudam
              nossos clientes a alcançar seus objetivos de venda online.
            </p>
          </div>
          <div class="col-md-4 div_rafik">
            <img
              class="img_rafik"
              src="../assets/img/icon_faca_parte/img_rafik.png"
            />
            <h5 class="h5_title">Tratamento de imagem</h5>
            <p class="descricao_img">
              Fornecemos serviços de tratamento de imagem de alta qualidade para
              ajudar a melhorar a aparência de seus produtos e aumentar o
              envolvimento do cliente.
            </p>
          </div>
          <div class="col-md-4 div_lup">
            <img
              class="img_lup"
              src="../assets/img/icon_faca_parte/img_lup.png"
            />
            <h5 class="h5_title">TAG orgânica de pesquisa</h5>
            <p class="descricao_img">
              Trabalhamos com as melhores práticas de SEO para garantir que o
              site de nossos clientes seja facilmente encontrado pelos motores
              de busca.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 div_mercado">
            <img
              class="img_ads"
              src="../assets/img/icon_faca_parte/img_dupla.png"
            />
            <h5 class="h5_title">Ánalise de Mercado</h5>
            <p class="descricao_img">
              Coleta e interpretação de informações sobre fatores internos e
              externos à empresa, a fim de identificar oportunidades e orientar
              decisões estratégicas.
            </p>
          </div>
          <div class="col-md-4 div_fretes">
            <img
              class="img_rafik"
              src="../assets/img/icon_faca_parte/img_placa_like.png"
            />
            <h5 class="h5_title">Logística de fretes</h5>
            <p class="descricao_img">
              Te auxiliamos a desenvolver estratégias de fretes eficazes para
              melhorar a experiência do cliente e aumentar as vendas.
            </p>
          </div>
          <div class="col-md-4 div_acompanha">
            <img
              class="img_lup"
              src="../assets/img/icon_faca_parte/img_ima.png"
            />
            <h5 class="h5_title">Impulsionamento de ADS</h5>
            <p class="descricao_img">
              Ajudamos nossos clientes a monitorar suas campanhas publicitárias,
              fornecendo dados e análises em tempo real. Isso nos permite
              ajustar e melhorar continuamente a campanha para alcançar melhores
              resultados.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 div_abc">
            <img
              class="img_ads"
              src="../assets/img/icon_faca_parte/img_grafic.png"
            />
            <h5 class="h5_title">Curva ABC</h5>
            <p class="descricao_img">
              Nós ajudamos nossos clientes a criar uma curva A de vendas para
              entender melhor o desempenho de seus negócios e identificar
              oportunidades para melhorar.
            </p>
          </div>
          <div class="col-md-4 div_sac">
            <img
              class="img_rafik"
              src="../assets/img/icon_faca_parte/img_cell.png"
            />
            <h5 class="h5_title">Acompanhamento de Sac</h5>
            <p class="descricao_img">
              Temos uma equipe de atendimento ao cliente altamente treinada e
              experiente pronta para atender às necessidades dos seus clientes,
              fornecendo um serviço rápido e eficiente.
            </p>
          </div>
          <div class="col-md-4 div_venda">
            <img
              class="img_lup"
              src="../assets/img/icon_faca_parte/img_meet.png"
            />
            <h5 class="h5_title">Estratégias de venda</h5>
            <p class="descricao_img">
              Auxiliamos nossos clientes a desenvolver estratégias de venda
              eficazes para ajudá-los a vender mais e a crescer seus negócios.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="cloud">
      <div class="container">
        <h1 class="title_cloud">Marketplaces</h1>
        <p class="text_cloud">
          Nossa solução é integrada aos principais Marketplaces do mercado, como
          Mercado Livre, Magalu, MadeiraMadeira e muito mais!
        </p>
        <div class="div_img_cloud">
          <img class="img_cloud" src="../assets/img/logo_cloud.png" />
          <img class="magalu" src="../assets/img/img_cloud/magalu.png" />
          <img class="integra" src="../assets/img/img_cloud/integra.png" />
          <img class="sky" src="../assets/img/img_cloud/sky.png" />

          <img class="img_linha1" src="../assets/img/img_cloud/1.png" />
          <img class="img_linha2" src="../assets/img/img_cloud/2.png" />
          <img class="img_linha3" src="../assets/img/img_cloud/3.png" />

          <img class="img_linha4" src="../assets/img/img_cloud/4.png" />
          <img class="img_linha5" src="../assets/img/img_cloud/5.png" />
          <img class="img_linha6" src="../assets/img/img_cloud/6.png" />

          <img
            class="americanas"
            src="../assets/img/img_cloud/americanas.png"
          />
          <img class="madeira" src="../assets/img/img_cloud/madeira.png" />
          <img class="livre" src="../assets/img/img_cloud/livre.png" />
        </div>
        <!-- <div class="div_btn_cloud">
          <button type="button" class="btn_lista_completa">
            <span class="tex_lista_completa">Acesse a lista completa</span>
          </button>
        </div>-->
      </div>
    </section>
    <section id="faturar">
      <div class="container">
        <h1 class="title_fatura">Comece a faturar mais agora!</h1>
        <p class="text_fatura">
          Negócios eficientes vendem mais! Conte com a Smart Sellers para
          gerenciar seu negócio de forma inteligente.
        </p>
        <div class="gif_help">
          <img class="helpme" src="../assets/img/help.gif" />
        </div>
        <div class="quad_faturar">
          <div class="colun_quad_linha">
            <img class="triangle" src="../assets/img/hand.png" />
            <h5 class="text_quad">Praticidade</h5>
            <p class="decotation">Simplificamos a gestão da sua loja</p>
          </div>
          <div class="colun_quad_linha2">
            <img class="triangle" src="../assets/img/medol.png" />
            <h5 class="text_quad">Os maiores marketplaces</h5>
            <p class="decotation">
              Seus produtos nos maiores marketplaces do Brasil
            </p>
          </div>
          <div class="colun_quad">
            <img class="triangle" src="../assets/img/team.png" />
            <h5 class="text_quad">Equipe qualificada</h5>
            <p class="decotation">
              Assessoria especializada para o seu negócio decolar
            </p>
          </div>
        </div>
        <div class="div_triangle">
          <img class="triangle_green" src="../assets/img/triangle.png" />
        </div>
      </div>
    </section>
    <section id="plataforma_web">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <p class="little_title">Nossa plataforma web</p>
            <h2 class="descricao_plataforma">
              Uma plataforma com tudo que seu comércio precisa
            </h2>
            <div class="plus_number">
              <h4 class="vinteQuatro">+24</h4>
              <h4 class="dezK">+10k</h4>
            </div>
            <div class="plus_text">
              <p class="descri_number1">Marketplaces integrados</p>
              <p class="descri_number2">Vendedores na plataforma</p>
            </div>
            <!--<div class="div_btn2">
              <button type="button" class="btn_cmc_agr">
                <span class="text_btn_agora">Comece agora</span>
              </button>
            </div>-->
          </div>
          <div class="col-md-4">
            <div class="quad_web">
              <img
                class="img_icon1"
                src="../assets/img/img_plataforma_web/centralizar.png"
              />
              <div class="conetudo_quad_web">
                <h6 class="title_quad_web">Centralize suas lojas</h6>
                Integre todas as suas lojas online em uma plataforma
                centralizada
              </div>
            </div>
            <div class="quad_web">
              <img
                class="img_icon1"
                src="../assets/img/img_plataforma_web/gerenciar.png"
              />
              <div class="conetudo_quad_web">
                <h6 class="title_quad_web">Gerencie seus pedidos</h6>
                Gerencie inventário, preços, pedidos e remessas em tempo real em
                todas as suas lojas online
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="quad_web">
              <img
                class="img_icon1"
                src="../assets/img/img_plataforma_web/automatizar.png"
              />
              <div class="conetudo_quad_web">
                <h6 class="title_quad_web">Automatize processos</h6>
                Atualize preços e estoques em várias plataformas de ecommerce de
                uma só vez
              </div>
            </div>
            <div class="quad_web">
              <img
                class="img_icon1"
                src="../assets/img/img_plataforma_web/maximizar.png"
              />
              <div class="conetudo_quad_web_space">
                <h6 class="title_quad_web">Maximize seus resultados</h6>
                Relatórios abrangentes que permitem que você analise e
                compreenda melhor as vendas, a rentabilidade e o desempenho em
                todas as suas lojas online
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="duvidas_frequentes">
      <div class="container">
        <div class="row">
          <div class="col-md-12 div_title_duvida">
            <h1 class="title_duvidas">Dúvidas Frequentes</h1>
          </div>
        </div>
        <div class="col-md-10 offset-1 quad_duvidas">
          <div class="titulo" @click="animationQuad('1')">
            <div class="title_duvida">
              Como faço para contratar a Smart Sellers?
            </div>
            <div class="div_plus" v-if="state.open_div_1 == false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-lg pluss"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </div>
            <div class="div_plus" v-if="state.open_div_1 == true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dash pluss"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          </div>
          <Collapse :when="state.open_div_1" class="my-class">
            <p>This is a paragraph.</p>
          </Collapse>
        </div>
        <div class="col-md-10 offset-1 quad_duvidas">
          <div class="titulo" @click="animationQuad('2')">
            <div class="title_duvida">Como funciona o SmartHub?</div>
            <div class="div_plus" v-if="state.open_div_2 == false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-lg pluss"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </div>
            <div class="div_plus" v-if="state.open_div_2 == true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dash pluss"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          </div>
          <Collapse :when="state.open_div_2" class="my-class">
            <p>This is a paragraph.</p>
          </Collapse>
        </div>
        <div class="col-md-10 offset-1 quad_duvidas">
          <div class="titulo" @click="animationQuad('3')">
            <div class="title_duvida">
              Posso me cadastrar no Smart Sellers com CPF?
            </div>
            <div class="div_plus" v-if="state.open_div_3 == false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-lg pluss"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </div>
            <div class="div_plus" v-if="state.open_div_3 == true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dash pluss"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          </div>
          <Collapse :when="state.open_div_3" class="my-class">
            <p>This is a paragraph.</p>
          </Collapse>
        </div>
        <div class="col-md-10 offset-1 quad_duvidas">
          <div class="titulo" @click="animationQuad('4')">
            <div class="title_duvida">Quanto custa?</div>
            <div class="div_plus" v-if="state.open_div_4 == false">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-plus-lg pluss"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                />
              </svg>
            </div>
            <div class="div_plus" v-if="state.open_div_4 == true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-dash pluss"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"
                />
              </svg>
            </div>
          </div>
          <Collapse :when="state.open_div_4" class="my-class">
            <p>This is a paragraph.</p>
          </Collapse>
        </div>
        <h4 class="mais_duvidas">
          Tem mais alguma dúvida?
          <a class="nossa_equipe" href="/contato">Fale com nossa equipe</a>
        </h4>
      </div>
    </section>
    <section id="contato">
      <div class="banner_contato">
        <div class="container">
          <div class="row">
            <div class="col-md-6 div_descricao">
              <h1 class="text_form">Aumente suas vendas e otimize seu tempo</h1>
              <p class="desc_form">
                Converse com um consultor especializado sobre as necessidades do
                seu negócio
              </p>
            </div>
            <div class="col-md-5 md-offset-1 d-md-none d-lg-block">
              <div class="form_email">
                <h5 class="title_form">
                  Quer receber o contato da nossa equipe de especialistas?
                  Preencha o formulário
                </h5>
                <input
                  type="text"
                  class="form-group"
                  placeholder="Seu nome"
                  v-model="state.nome"
                />
                <input
                  type="text"
                  class="form-group"
                  placeholder="Celular/WhatsApp"
                  v-model="state.tel"
                />
                <input
                  type="text"
                  class="form-group"
                  placeholder="Seu melhor e-mail"
                  v-model="state.email"
                />
                <textarea
                  type="text"
                  class="form-group"
                  placeholder="Digite aqui sua mensagem"
                  v-model="state.msg"
                ></textarea>
                <button type="button" class="btn_enviar" @click="sendForm">
                  Enviar
                </button>
              </div>
            </div>
            <div class="col-md-6 offset- d-none d-md-block d-lg-none">
              <div class="form_email">
                <h5 class="title_form">
                  Quer receber o contato da nossa equipe de especialistas?
                  Preencha o formulário
                </h5>
                <input
                  type="text"
                  class="form-group"
                  placeholder="Seu nome"
                  v-model="state.nome"
                />
                <input
                  type="text"
                  class="form-group"
                  placeholder="Celular/WhatsApp"
                  v-model="state.tel"
                />
                <input
                  type="text"
                  class="form-group"
                  placeholder="Seu melhor e-mail"
                  v-model="state.email"
                />
                <textarea
                  type="text"
                  class="form-group"
                  placeholder="Digite aqui sua mensagem"
                  v-model="state.msg"
                ></textarea>
                <button type="button" class="btn_enviar" @click="sendForm">
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <bottom />
  </div>
</template>

<script>
import bottom from "../layout/bottom.vue";
import top from "../layout/top.vue";
import { reactive } from "vue";
import services from "../services";
import { useRouter } from "vue-router";
export default {
  components: { bottom, top },
  setup() {
    const router = useRouter();
    const state = reactive({
      isOpen: true,
      nome: "",
      tel: "",
      email: "",
      msg: "",
      open_div_1: false,
      open_div_2: false,
      open_div_3: false,
      open_div_4: false,
    });
    async function sendForm() {
      const { errors } = await services.contato.sendForm({
        nome: state.nome,
        tel: state.tel,
        email: state.email,
        msg: state.msg,
      });
      console.log(errors);
    }
    async function animationQuad(div) {
      switch (div) {
        case "1":
          if (state.open_div_1 == false) {
            state.open_div_1 = true;
          } else {
            state.open_div_1 = false;
          }
          break;
        case "2":
          console.log("div 2");
          if (state.open_div_2 == false) {
            state.open_div_2 = true;
          } else {
            state.open_div_2 = false;
          }
          break;
        case "3":
          console.log("div 3");
          if (state.open_div_3 == false) {
            state.open_div_3 = true;
          } else {
            state.open_div_3 = false;
          }
          break;
        case "4":
          console.log("div 4");
          if (state.open_div_4 == false) {
            state.open_div_4 = true;
          } else {
            state.open_div_4 = false;
          }
          break;
        default:
          break;
      }
    }
    return {
      bottom,
      top,
      state,
      sendForm,
      animationQuad,
    };
  },
};
</script>
<style scoped>
.my-class {
  transition: height 300ms cubic-bezier(0.3, 0, 0.6, 1);
}
</style>
<style scoped>
/*---Section Home---*/
html {
  scroll-behavior: smooth;
}
#home {
  font-family: "DMSans-Regular";
  background: linear-gradient(180deg, #ffffff 0%, #eef6ff 100%);
  margin-bottom: 70px;
}
.conteudo {
  margin-top: 70px;
}
.title_home {
  width: 660px;
  height: 165px;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #001942;
}
.tp_recurso {
  background-color: #eef6ff;
}
.text_home {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #535f74;
}
.img_div {
  position: relative;
}
.img_cardboard {
  width: 90%;
  position: absolute;
  left: 90px;
  bottom: 0;
  z-index: 100;
}
.row_home {
  height: 500px;
}
.btn_cmc_agr {
  text-align: center;
  background: #4b89ee;
  border-color: #4b89ee;
  border: #4b89ee solid 1px;
  border-radius: 6px;
  transition: 0.5s;
  margin-right: 10px;
  height: 40px;
  width: 150px;
  margin-bottom: 50px;
}
.btn_cmc_agr:hover {
  background: #315692;
}
.text_btn_agora {
  color: #fff;
}
.btn_vantagens {
  text-align: center;
  background: #ffffff;
  border-color: #4b89ee;
  border: #4b89ee solid 1px;
  border-radius: 6px;
  transition: 0.5s;
  height: 40px;
  width: 250px;
}
.btn_vantagens:hover {
  background: #eef6ff;
}
.text_btn_vantagens {
  color: #4b89ee;
  font-weight: 600;
}
.div_btn {
  display: flex;
}
.cash {
  width: 10%;
  left: 120px;
  top: 90px;
  opacity: 0.3;
}
.cart1 {
  left: 400px;
  width: 15%;
  opacity: 0.3;
  top: 77px;
}
.bag {
  width: 15%;
  left: 500px;
  bottom: 230px;
  opacity: 0.3;
}
.paper {
  width: 10%;
  left: 40px;
  opacity: 0.3;
  bottom: 40px;
}
.cart2 {
  width: 13%;
  top: 240px;
  opacity: 0.3;
}
/*---Section Faça Parte---*/
#faca_parte {
  font-family: "DMSans-Regular";
  background: linear-gradient(180deg, #ffffff 0%, #eef6ff 100%);
}
.descri_faca_parte {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  color: #535f74;
}
.title_faca_parte {
  text-align: center;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 55px;
  color: #001942;
}
.div_ads {
  text-align: center;
  margin-top: 15px;
}
.div_rafik {
  text-align: center;
  margin-top: 85px;
}
.div_lup {
  text-align: center;
}
.div_mercado {
  text-align: center;
  margin-top: 15px;
}
.div_fretes {
  text-align: center;
  margin-top: 20px;
}
.div_acompanha {
  text-align: center;
  margin-top: -60px;
}
.div_abc {
  text-align: center;
  margin-top: -25px;
}
.div_sac {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 50px;
}
.div_venda {
  text-align: center;
}
.img_ads {
}
.img_rafik {
}
.img_lup {
}
.descricao_img {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #535f74;
}
.h5_title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #001942;
}
/*---Section Cloud---*/
#cloud {
  background-color: #fff;
  font-family: "DMSans-Regular";
  height: 500px;
}
.title_cloud {
  color: #001942;
  font-family: "DMSans-Regular";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 55px;
  text-align: center;
  margin-top: 15px;
}
.text_cloud {
  color: #535f74;
  font-family: "DMSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
}
.div_img_cloud {
  position: relative;
  height: 360px;
}
.img_cloud {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 100;
}
.div_btn_cloud {
  text-align: center;
}
.btn_lista_completa {
  background-color: #f0f7ff;
  color: #4b89ee;
  border: solid 1px;
  border-color: #4b89ee;
  border-radius: 6px;
  padding: 8px;
  transition: 0.6s;
  width: 20%;
}
.btn_lista_completa:hover {
  background-color: #fff;
  transform: none;
}
.tex_lista_completa {
  color: #4b89ee;
  font-weight: 600;
}
.img_linha1 {
  position: absolute;
  top: 65px;
  left: 331px;
}
.img_linha2 {
  position: absolute;
  top: 180px;
  left: 298px;
}
.img_linha3 {
  position: absolute;
  top: 240px;
  left: 295px;
}
.img_linha4 {
  position: absolute;
  top: 65px;
  right: 352px;
}
.img_linha5 {
  position: absolute;
  top: 180px;
  right: 277px;
}
.img_linha6 {
  position: absolute;
  top: 240px;
  right: 313px;
}
.magalu {
  position: absolute;
  top: 37px;
  left: 210px;
}
.integra {
  position: absolute;
  top: 160px;
  left: 160px;
}
.sky {
  position: absolute;
  top: 265px;
  left: 110px;
}
.americanas {
  position: absolute;
  top: 42px;
  right: 235px;
}
.madeira {
  position: absolute;
  top: 175px;
  right: 80px;
}
.livre {
  position: absolute;
  top: 270px;
  right: 165px;
}
/*---Section Faturar---*/
#faturar {
  background-color: #fff;
  font-family: "DMSans-Regular";
  margin-top: 0;
}
.title_fatura {
  text-align: center;
  font-weight: 700;
  font-size: 36px;
  line-height: 55px;
  color: #001942;
}
.text_fatura {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #535f74;
  margin-bottom: 0;
}
.triangle_green {
}
.colun_quad {
  padding-left: 10px;
  width: 33%;
}
.colun_quad_linha {
  border-right: solid 1px rgba(193, 193, 193, 0.585);
  width: 33%;
}
.colun_quad_linha2 {
  border-right: solid 1px rgba(193, 193, 193, 0.585);
  padding-left: 10px;
  width: 33%;
}
.div_triangle {
  text-align: center;
}
.quad_faturar {
  background: #ffffff;
  border: 1px solid #eeeeee;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  display: flex;
  position: absolute;
  padding: 20px;
  margin-top: -30px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  text-align: left;
  left: 0;
  right: 0;
}
.text_quad {
  color: #4b89ee;
  font-size: 13pt;
  margin-bottom: 0;
  margin-top: 10px;
}
.decotation {
  color: #535f74;
  margin-bottom: 0;
  font-size: 11pt;
}
.linha {
  color: #eee;
  height: 123px;
  width: 50%;
  border: solid 1px;
  margin-left: 5px;
  margin-right: 10px;
}
.gif_help {
  text-align: center;
}
/*---Section Plataforma Web---*/
#plataforma_web {
  background: #f0f7ff;
  font-family: "DMSans-Regular";
}
.div_icn_plataforma {
  display: flex;
}
.little_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 17px;
  color: #8ec252;
  margin-top: 50px;
}
.descricao_plataforma {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  color: #001942;
}
.plus_number {
  display: flex;
}
.dezK {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 55px;
  color: #8ec252;
  margin-left: 95px;
}
.vinteQuatro {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 55px;
  color: #8ec252;
}
.plus_text {
  display: flex;
}
.descri_number1 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #535f74;
  margin-left: 5px;
  margin-top: -15px;
}
.descri_number2 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #535f74;
  margin-left: -26px;
  margin-top: -15px;
}
.div_btn2 {
  margin-left: 3px;
}
.quad_web {
  display: flex;
  margin-top: 65px;
}
.img_icon1 {
  align-self: flex-start;
}
.conetudo_quad_web {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #535f74;
  padding-left: 10px;
}
.conetudo_quad_web_space {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #535f74;
  padding-left: 10px;
}
.title_quad_web {
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #001942;
}
/*---Section Duvidas Frequentes---*/
#duvidas_frequentes {
  font-family: "DMSans-Regular";
}
.titulo {
  width: 100%;
}
.title_duvidas {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 55px;
}
.div_title_duvida {
  text-align: center;
}
.quad_duvidas {
  align-items: center;
  padding: 12px 32px;
  background: #ffffff;
  border: 1px solid #f0f7ff;
  border-radius: 4px;
  margin-bottom: 5px;
  cursor: pointer;
}
.quad_duvidas .titulo {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.title_duvida {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 55px;
  color: #535f74;
  width: 80%;
  float: left;
}
.div_plus {
  background-color: transparent;
  width: 10%;
  float: right;
  margin-top: 17px;
}
.pluss {
  color: #4b89ee;
  width: 25%;
  height: 25px;
  float: right;
}
.nossa_equipe {
  color: #4b89ee;
  text-decoration: none;
}
.mais_duvidas {
  color: #535f74;
  text-align: center;
  margin-top: 40pt;
}
/*---Section Contato---*/
#contato {
  font-family: "DMSans-Regular";
}
.banner_contato {
  background-color: #4b89ee;
  height: 250px;
  margin-top: 230px;
  margin-bottom: 200px;
}
.form_email {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 50px;
  margin-top: -130px;
}
input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #868b94;
  border-radius: 5px;
  background: #fff;
  color: #868b94;
  resize: none;
  height: 50px;
  outline: none;
  font-family: "DMSans-Regular";
  margin-bottom: 5px;
}
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #868b94;
  border-radius: 5px;
  background: #fff;
  color: #868b94;
  resize: none;
  height: 50px;
  outline: none;
  font-family: "DMSans-Regular";
  height: 100px;
}
.title_form {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  text-align: center;
  color: #001942;
  margin-bottom: 30px;
}
.text_form {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #ffffff;
}
.desc_form {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: #ffffff;
}
.div_descricao {
  padding: 30px;
}
.btn_enviar {
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #4b89ee;
  border: #4b89ee solid 1px;
  color: #fff;
  border-radius: 6px;
  margin-top: 10px;
  transition: 0.5s;
}
.btn_enviar:hover {
  background: #3666b4;
  border: #3666b4 solid 1px;
}
@media (min-width: 1400px) {
  .img_linha3 {
    position: absolute;
    top: 240px;
    left: 385px;
  }
  .sky {
    position: absolute;
    top: 265px;
    left: 189px;
  }
  .img_linha2 {
    position: absolute;
    top: 180px;
    left: 389px;
  }
  .integra {
    position: absolute;
    top: 160px;
    left: 242px;
  }
  .magalu {
    left: 296px;
  }
  .img_linha1 {
    left: 423px;
  }
  .img_linha4{
    right: 444px;
  }
  .americanas{
    right: 320px;
  }
  .img_linha5{
    right: 367px;
  }
  .madeira{
    right: 165px;
  }
  .img_linha6{
    right: 402px;
  }
  .livre{
    right: 255px;
  }
  
}
@media (min-width: 992px) and (max-width: 1199px) {
  #home {
    margin-bottom: 20px;
  }
  #cloud {
    height: 435px;
  }
  .img_cardboard {
    width: 85%;
    position: absolute;
    left: 20px;
    bottom: 0;
  }
  .div_img_cloud {
    height: 310px;
  }
  .title_home {
    font-size: 35px;
    line-height: 45px;
  }
  .text_home {
    margin-top: -25px;
  }
  .paper {
    bottom: 16px;
    left: -10px;
  }
  .cash {
    left: 30px;
    top: 185px;
  }
  .cart1 {
    width: 13%;
    left: 298px;
    top: 170px;
  }
  .bag {
    width: 13%;
    left: 350px;
    bottom: 191px;
  }
  .cart2 {
    width: 14%;
    right: 390px;
    top: 310px;
  }
  .text_form {
    font-size: 38px;
  }
  .desc_form {
    font-size: 22px;
    line-height: 31px;
  }
  .title_form {
    font-size: 24px;
    line-height: 25px;
    text-align: justify;
    color: #001942;
    margin-bottom: 25px;
  }
  .img_icon1 {
    width: 65px;
  }
  .quad_faturar {
    padding: 20px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    text-align: left;
    left: 0;
    right: 0;
  }
  .img_cloud {
    width: 35%;
  }
  .img_linha1 {
    top: 70px;
    left: 268px;
    width: 10%;
  }
  .img_linha2 {
    top: 145px;
    left: 241px;
    width: 10%;
  }
  .img_linha3 {
    top: 191px;
    left: 248px;
    width: 10%;
  }
  .img_linha4 {
    top: 65px;
    right: 250px;
    width: 10%;
  }
  .img_linha5 {
    top: 146px;
    right: 239px;
    width: 10%;
  }
  .img_linha6 {
    top: 191px;
    right: 255px;
    width: 10%;
  }
  .magalu {
    top: 41px;
    left: 145px;
  }
  .integra {
    top: 130px;
    left: 100px;
  }
  .sky {
    top: 210px;
    left: 65px;
  }
  .americanas {
    right: 135px;
  }
  .madeira {
    top: 135px;
    right: 43px;
  }
  .livre {
    top: 225px;
    right: 110px;
  }
  .btn_lista_completa {
    padding: 7px;
    width: 22%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #home {
    height: 420px;
    margin-bottom: 10px;
    height: 380px;
  }
  .title_home {
    height: 65px;
    font-style: normal;
    font-weight: 700;
    font-size: 31px;
    line-height: 35px;
  }
  .img_cardboard {
    width: 100%;
    left: 17px;
    top: 115px;
  }
  .div_sac {
    margin-bottom: 20px;
  }
  .triangle_green {
    width: 700px;
  }
  .quad_faturar {
    padding: 20px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    text-align: left;
    left: 0;
    right: 0;
  }
  .decotation {
    font-size: 10pt;
  }
  .text_quad {
    font-size: 12pt;
  }
  .img_icon1 {
    width: 70px;
  }
  .title_quad_web {
    font-size: 17px;
    line-height: 17px;
  }
  .img_icon1 {
    width: 70px;
    margin-top: 65px;
    margin-left: 10px;
    margin-bottom: 5px;
  }
  .quad_web {
    display: contents;
  }
  #plataforma_web {
    margin-bottom: 30px;
  }
  .title_duvida {
    font-size: 19px;
  }
  .text_form {
    font-size: 35px;
    line-height: 30px;
  }
  .desc_form {
    font-size: 21px;
    line-height: 25px;
  }
  .title_form {
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin-bottom: 20px;
  }
  .form_email {
    padding: 30px;
  }
  .banner_contato {
    height: 245px;
    margin-top: 195px;
    margin-bottom: 145px;
  }
  .plus_number {
    display: none;
  }
  .plus_text {
    display: none;
  }
  .descricao_plataforma {
    font-size: 27px;
    line-height: 40px;
  }
  .little_title {
    margin-top: 35pt;
  }
  .cash {
    left: 30px;
    top: 140px;
  }
  .cart1 {
    left: 240px;
    width: 15%;
    top: 110px;
  }
  .bag {
    width: 14%;
    left: 280px;
    bottom: 55px;
  }
  .paper {
    bottom: 140px;
    left: -5px;
  }
  .cart2 {
    width: 15%;
    top: 225px;
    margin-left: -42px;
  }
  .bag {
    width: 14%;
    left: 280px;
    bottom: 270px;
  }
  .conteudo {
    margin-top: 30px;
  }
  .btn_cmc_agr {
    width: 35%;
    margin-right: 10px;
  }
  .btn_vantagens {
    width: 237px;
  }
  .img_cloud {
    width: 45%;
  }
  .img_linha1 {
    left: 200px;
    width: 9%;
    top: 45px;
  }
  .magalu {
    top: 20px;
    left: 95px;
    width: 15%;
  }
  .img_linha2 {
    left: 185px;
    width: 9%;
    top: 110px;
  }
  .integra {
    top: 98px;
    left: 85px;
    width: 15%;
  }
  .img_linha3 {
    left: 157px;
    width: 9%;
    top: 165px;
  }
  .sky {
    top: 177px;
    left: 50px;
    width: 15%;
  }
  .img_linha4 {
    top: 54px;
    right: 198px;
    width: 9%;
  }
  .americanas {
    width: 15%;
    right: 90px;
    top: 32px;
  }
  .img_linha5 {
    width: 9%;
    top: 115px;
    right: 163px;
  }
  .img_linha6 {
    width: 9%;
    top: 165px;
    right: 159px;
  }
  .madeira {
    width: 15%;
    right: 55px;
    top: 111px;
  }
  .livre {
    width: 15%;
    right: 53px;
    top: 187px;
  }
  .conetudo_quad_web_space {
    padding-bottom: 50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  #home {
    margin-bottom: 20px;
  }
  #cloud {
    height: 350px;
  }
  .img_cardboard {
    width: 57%;
    bottom: 0;
    margin-left: 75px;
  }
  .title_home {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    width: 540px;
    height: 55px;
  }
  .text_home {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 540px;
  }
  .bag {
    width: 10%;
    left: 469px;
    bottom: 110px;
  }
  .cart1 {
    left: 388px;
    top: auto;
    bottom: 219px;
    width: 11%;
  }
  .paper {
    width: 7%;
    bottom: 15px;
    left: 126px;
  }
  .cash {
    bottom: 220px;
    width: 10%;
    left: 155px;
    top: auto;
    width: 8%;
  }
  .cart2 {
    width: 11%;
    top: auto;
    bottom: 100px;
    left: 80px;
  }
  .btn_vantagens {
    width: 237px;
  }
  .btn_cmc_agr {
    width: 130px;
    margin-left: 20px;
  }
  .conteudo {
    margin-top: 20px;
  }
  .descri_faca_parte {
    margin-bottom: 0;
  }
  .descricao_img {
    margin-bottom: 0;
  }
  .div_ads {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_rafik {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_lup {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_mercado {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .div_fretes {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_acompanha {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_abc {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_sac {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .title_fatura {
    line-height: 1;
  }
  .div_venda {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .triangle_green {
    width: 510px;
  }
  .quad_faturar {
    padding: 11px;
    margin-top: -30px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    text-align: left;
    left: 0;
    right: 0;
  }
  .text_quad {
    font-size: 11pt;
  }
  .decotation {
    font-size: 9pt;
  }
  .descricao_plataforma {
    text-align: center;
    line-height: 35px;
    font-size: 35px;
  }
  .plus_number {
    display: none;
  }
  .descri_number1 {
    display: none;
  }
  .descri_number2 {
    display: none;
  }
  .div_btn2 {
    text-align: center;
    margin-left: 0;
  }
  .title_duvidas {
    font-size: 30px;
  }
  .title_duvida {
    font-size: 20px;
    line-height: 30px;
  }
  .mais_duvidas {
    margin-top: 30pt;
  }
  .banner_contato {
    margin-top: 100px;
  }
  .form_email {
    margin-top: 0;
  }
  .banner_contato {
    margin-bottom: 550px;
  }
  .magalu {
    position: static;
    width: 30%;
  }
  .quad_web {
    margin-top: 25px;
  }
  .integra {
    position: static;
    width: 30%;
    margin: 20px;
  }
  .sky {
    position: static;
    width: 30%;
  }
  .americanas {
    position: static;
    width: 30%;
  }
  .madeira {
    position: static;
    width: 30%;
    margin: 20px;
  }

  .livre {
    position: static;
    width: 30%;
    margin-top: 30px;
  }
  .img_linha1 {
    display: none;
  }
  .img_linha2 {
    display: none;
  }
  .img_linha3 {
    display: none;
  }
  .img_linha4 {
    display: none;
  }
  .img_linha5 {
    display: none;
  }
  .img_linha6 {
    display: none;
  }
  .img_cloud {
    display: none;
  }
  .div_img_cloud {
    height: 200px;
  }
  .btn_lista_completa {
    width: 50%;
  }
  #plataforma_web {
    padding-bottom: 50px;
  }
}
@media (max-width: 575px) {
  #home {
    margin-bottom: 30px;
  }
  #cloud {
    height: 900px !important;
  }
  .div_ads {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_rafik {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_lup {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_mercado {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .div_fretes {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_acompanha {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_abc {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_sac {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .div_venda {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 0px;
  }
  .title_home {
    width: 100%;
    font-style: normal;
    color: #001942;
    text-align: center;
    margin-top: -50px;
    height: 75px;
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 1;
  }
  .img_cardboard {
    width: 50%;
    position: absolute;
    left: 160px;
    top: auto;
    bottom: 0;
  }
  .text_home {
    line-height: 1;
    letter-spacing: -0.02em;
    color: #535f74;
    text-align: justify;
    font-size: 18px;
  }
  .btn_cmc_agr {
    width: 115px;
  }
  .text_btn_agora {
    color: #fff;
    font-size: 11pt;
  }
  .btn_vantagens {
    width: 206px;
  }
  .text_btn_vantagens {
    font-size: 10pt;
  }
  .cart2 {
    width: 11%;
    top: -50px;
    left: 75px;
  }
  .paper {
    width: 7%;
    bottom: 10px;
    left: 105px;
  }
  .cash {
    width: 9%;
    left: 120px;
    top: -120px;
  }
  .cart1 {
    left: 365px;
    width: 11%;
    top: auto;
    bottom: 200px;
  }
  .bag {
    width: 11%;
    bottom: 105px;
    left: 430px;
  }
  #cloud {
    height: 830px;
  }
  .title_faca_parte {
    font-weight: 700;
    font-size: 25px;
    line-height: 1;
  }
  .descri_faca_parte {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 0;
  }
  .descricao_img {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 0;
  }
  .title_cloud {
    margin-top: 15px;
    margin-bottom: 0;
  }
  .title_fatura {
    font-size: 23px;
    line-height: 1;
  }
  .text_fatura {
    font-size: 17px;
    line-height: 1;
  }
  .triangle_green {
    display: none;
  }
  .helpme {
    width: 100%;
  }
  .quad_faturar {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }
  .colun_quad {
    padding: 10px;
  }
  .decotation {
    color: #535f74;
    margin-bottom: 0;
    font-size: 8pt;
    text-align: left;
    line-height: 1;
  }
  .text_quad {
    font-size: 11pt;
    line-height: 1;
  }
  .linha {
    width: 1px;
  }
  #faturar {
    margin-bottom: 140px;
  }
  .plus_number {
    display: none;
  }
  .plus_text {
    display: none;
  }
  .descricao_plataforma {
    font-size: 28px;
    line-height: 1;
  }
  .quad_web {
    margin-top: 40px;
  }
  .conetudo_quad_web {
    font-size: 15px;
    line-height: 1;
  }
  .img_icon1 {
    width: 65px;
  }
  .title_duvidas {
    font-size: 30px;
    line-height: 1;
    margin-bottom: 50px;
    margin-top: 20px;
  }
  .title_duvida {
    font-size: 15px;
    line-height: 1;
  }
  .quad_duvidas {
    height: 75px;
    margin-left: 0px;
  }
  .mais_duvidas {
    margin-top: 20pt;
    font-size: 11pt;
    line-height: 1;
  }
  .banner_contato {
    margin-top: 25px;
  }
  .text_form {
    font-size: 20pt;
    line-height: 1;
    text-align: center;
  }
  .form_email {
    margin-top: -15px;
    padding: 30px;
  }
  .desc_form {
    font-size: 12pt;
    line-height: 1;
    color: #ffffff;
    text-align: center;
  }
  .title_form {
    font-size: 18px;
    line-height: 1;
    text-align: justify;
    margin-bottom: 20px;
  }
  #contato {
    height: 620px;
  }
  .quad_web {
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .magalu {
    position: static;
    width: 51%;
    margin-bottom: 15px;
  }

  .integra {
    position: static;
    width: 51%;
    margin-bottom: 15px;
  }
  .sky {
    position: static;
    width: 51%;
    margin-bottom: 15px;
  }
  .americanas {
    position: static;
    width: 51%;
    margin-bottom: 15px;
  }
  .madeira {
    position: static;
    width: 51%;
    margin-bottom: 15px;
  }
  .livre {
    position: static;
    width: 51%;
    margin-bottom: 15px;
    margin-top: 30px;
  }
  .img_linha1 {
    display: none;
  }
  .img_linha2 {
    display: none;
  }
  .img_linha3 {
    display: none;
  }
  .img_linha4 {
    display: none;
  }
  .img_linha5 {
    display: none;
  }
  .img_linha6 {
    display: none;
  }
  .img_cloud {
    display: none;
  }
  .div_img_cloud {
    height: 550px;
    text-align: center;
  }
  .btn_lista_completa {
    width: 50%;
    padding: 5px;
    font-size: 10pt;
  }
  #cloud {
    height: 700px;
  }
  #plataforma_web {
    padding-bottom: 20px;
  }
}
@media (max-width: 472px) {
  .bag {
    width: 11%;
    bottom: 85px;
    left: 355px;
  }
  .cart1 {
    left: 280px;
    width: 11%;
    top: auto;
    bottom: 175px;
  }
  .cash {
    width: 9%;
    left: 155px;
    top: -105px;
  }
  .cart2 {
    width: 11%;
    top: -30px;
    left: 96px;
  }
  .paper {
    width: 7%;
    bottom: 10px;
    left: 125px;
  }
  #cloud {
    height: 820px !important;
  }
}
@media (max-width: 362px) {
  .bag {
    width: 11%;
    bottom: 85px;
    left: 35px;
  }
  .cart1 {
    left: 340px;
    width: 11%;
    top: auto;
    bottom: 175px;
  }
  .cash {
    width: 9%;
    left: 105px;
    top: -105px;
  }
  .cart2 {
    width: 11%;
    top: -30px;
    left: 96px;
  }
  .paper {
    width: 7%;
    bottom: 10px;
    left: 125px;
  }
  #cloud {
    height: 660px !important;
  }
  .title_fatura {
    font-size: 22px;
  }
}
</style>

