<template>
  <div>
    <top />
    <section id="contato">
      <div class="row">
        <div class="col-md-6 d-block d-sm-block d-md-none">
          <div class="conteudo">
            <img class="contact" src="../assets/img/contactgif.gif " />
            <h1>Converse conosco!</h1>
            <p>
              Quer receber o contato da nossa equipe de especialistas? Preencha
              o formulário, logo entraremos em contato.
            </p>
          </div>
        </div>
        <div class="col-md-6 didi">
          <div class="back_blue">
            <div class="col-md-6">
              <div class="form_email">
                <input
                  type="text"
                  class="form-group"
                  placeholder="Seu nome"
                  v-model="state.nome"
                />
                <input
                  type="text"
                  class="form-group"
                  placeholder="Celular/WhatsApp"
                  v-model="state.tel"
                />
                <input
                  type="text"
                  class="form-group"
                  placeholder="Seu melhor e-mail"
                  v-model="state.email"
                />
                <textarea
                  type="text"
                  class="form-group"
                  placeholder="Digite aqui sua mensagem"
                  v-model="state.msg"
                ></textarea>
                <button type="button" class="btn_enviar" @click="sendForm()">
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-md-block">
          <div class="conteudo">
            <img class="contact" src="../assets/img/contactgif.gif " />
            <h1>Converse conosco!</h1>
            <p>
              Quer receber o contato da nossa equipe de especialistas? Preencha
              o formulário, logo entraremos em contato.
            </p>
          </div>
          <div class="col-md-3 div_arow">
            <img class="arow" src="../assets/img/arow.png" />
          </div>
        </div>
      </div>
    </section>
    <bottom />
  </div>
</template>
<script>
import services from "../services";
import bottom from "../layout/bottom.vue";
import top from "../layout/top.vue";
import { reactive } from "vue";
export default {
  components: { bottom, top },
  setup() {
    const state = reactive({
      nome: "",
      email: "",
      msg: "",
    });
    async function sendForm() {
      const { errors } = await services.contato.sendForm({
        nome: state.nome,
        tel: state.tel,
        email: state.email,
        msg: state.msg,
      });
      console.log(errors);
    }
    return {
      bottom,
      top,
      state,
      sendForm,
    };
  },
};
</script>
<style scoped>
#contato {
  font-family: "DMSans-Regular";
  background: #f0f7ff;
  box-shadow: 0 5px 10px -5px rgba(118, 118, 118, 0.687);
}
.back_blue {
  background-color: #4b89ee;
  height: 250px;
  margin-top: 140px;
  margin-bottom: 200px;
}
.form_email {
  background: #ffffff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 50px;
  position: absolute;
  width: 30%;
  margin-top: -80px;
  margin-left: 170px;
}
input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #868b94;
  border-radius: 5px;
  background: #fff;
  color: #868b94;
  resize: none;
  height: 50px;
  outline: none;
  font-family: "DMSans-Regular";
  margin-bottom: 5px;
}
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #868b94;
  border-radius: 5px;
  background: #fff;
  color: #868b94;
  resize: none;
  height: 50px;
  outline: none;
  font-family: "DMSans-Regular";
  height: 100px;
}
.btn_enviar {
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #4b89ee;
  border: #4b89ee solid 1px;
  color: #fff;
  border-radius: 6px;
  margin-top: 10px;
  transition: 0.5s;
}
.btn_enviar:hover {
  background: #3666b4;
  border: #3666b4 solid 1px;
}
.row {
  margin-right: 0;
  padding-right: 0;
}
.conteudo {
  text-align: center;
}
h1 {
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #001942;
}
p {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #535f74;
}
.div_arow {
  position: relative;
  bottom: 75px;
  right: 110px;
}
.arow {
  width: 130px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .contact {
    width: 80%;
  }
  .form_email {
    margin-left: 140px;
  }
  h1 {
    font-size: 30px;
  }
  p {
    font-size: 17px;
  }
  .arow {
    width: 120px;
    margin-top: 25px;
    margin-left: 25px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .form_email {
    position: absolute;
    width: 31%;
    margin-top: -70px;
    margin-left: 110px;
  }
  .contact {
    width: 70%;
    margin-top: 60px;
  }
  h1 {
    font-size: 30px;
    line-height: 35px;
  }
  p {
    font-size: 17px;
    line-height: 23px;
    text-align: center;
  }
  .arow {
    width: 100px;
    margin-left: 40px;
    margin-top: 35px;
  }
  .form_email {
    width: 30%;
    margin-top: -75px;
    margin-left: 75px;
    padding: 25px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .form_email {
    border-radius: 4px;
    padding: 30px;
    position: absolute;
    width: 50%;
    margin-top: -80px;
    margin-left: 145px;
  }
  .back_blue {
    margin-bottom: 100px;
    width: 100%;
  }
  .contact {
    width: 60%;
  }
  .arow {
    display: none;
  }
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  P {
    font-size: 17px;
    margin-bottom: 0;
  }
}
@media (max-width: 575px) {
  .contact {
    width: 70%;
  }
  h1 {
    font-size: 30px;
  }
  .back_blue {
    height: 500px;
    margin-top: 20px;
    margin-bottom: 40px;
    width: 100%;
  }
  .form_email {
    background: #ffffff;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    padding: 50px;
    position: absolute;
    width: 100%;
    margin-top: 32px;
    margin-left: 0;
  }
  .didi {
    padding-right: 0;
  }
}
</style>