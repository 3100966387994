export default httpClient => ({
    sendForm: async ({ nome, tel, email, msg }) => {
        const response = await httpClient.post('/', {
            nome,
            tel,
            email,
            msg,
        })
        return { data: response.data }
    },
})