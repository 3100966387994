<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 espaco">
          <div class="row">
            <div class="col-md-3 div_logo">
              <a href="/"
                ><img class="img_logo" src="../assets/img/logo_2.png"
              /></a>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-facebook social_face"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-instagram social"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"
                  />
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-twitter social"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
                  />
                </svg>
              </div>
            </div>
            <div class="col-md-3">
              <h5>A empresa</h5>
              <a class="link_empresa" href="/sobre"><p>Sobre nós</p></a>
              <a class="link_empresa" href="/contato"><p>Contato</p></a>
            </div>
            <div class="col-md-3">
              <h5>E-mail</h5>
              <p>contato@smartsellers.com</p>
              <h5>Telefone/Whatsapp</h5>
              <p>(55) 5 5555-5555</p>
              <h5>Endereço</h5>
              <p>Rua Ceará, 5575 - Umuarama, PR - Brasil</p>
            </div>
            <div class="col-md-3">
              <h5>Fale Conosco</h5>
              <p>Contate os nossos consultores</p>
              <a
                href="https://api.whatsapp.com/send?phone=554498321895&text=Ol%C3%A1,%20gostaria%20de%20conhecer%20seus%20servi%C3%A7os!"
                ><button type="button" class="btn_fale_conosco">
                  <span class="text_btn_conosco">Iniciar Conversa</span>
                </button></a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 footer_linha">
          <hr />
          <p class="copy">
            Smart Sellers - Integração de ecommerce 2023 © Todos os direitos
            reservados
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
html {
  scroll-behavior: smooth;
}
* {
  font-family: "DMSans-Regular";
}
.espaco {
  margin-top: 70px;
}
.copy {
  font-family: "DMSans-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #535f74;
}
h5 {
  font-style: normal;
  font-family: "DMSans-Regular";
  margin-bottom: 3px;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #001942;
}
p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #535f74;
}
.btn_fale_conosco {
  background-color: #4b89ee;
  color: #fff;
  border: solid 1px;
  border-color: #4b89ee;
  border-radius: 6px;
  width: 63%;
  padding: 3px;
  transition: 0.6s;
}
.btn_fale_conosco:hover {
  background-color: #3666b4;
  transform: none;
}
.text_btn_conosco {
  color: #fff;
  font-weight: 600;
}
.div_logo {
  margin-top: -10px;
}
svg {
  color: #4b89ee;
}
.social {
  color: #4b89ee;
  width: 30px;
  height: 50px;
  margin-right: 10px;
  transition: 1s;
}
.social:hover {
  color: #fff;
}
.social_face {
  margin-left: 40px;
  color: #4b89ee;
  width: 30px;
  height: 50px;
  margin-right: 10px;
  transition: 1s;
}
.social_face:hover {
  color: #fff;
}
.footer_linha {
  text-align: center;
  margin-top: 5px;
}
.link_empresa{
  text-decoration: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .social_face {
    margin-left: 20px;
  }
  .btn_fale_conosco {
    width: 86%;
  }
  p {
    font-size: 14px;
    line-height: 1;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .div_logo {
    text-align: center;
  }
  p {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .btn_fale_conosco {
    width: 30%;
  }
}
@media (max-width: 575px) {
  .btn_fale_conosco {
    width: 35%;
  }
  .div_logo {
    text-align: center;
  }
  p {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 20px;
  }
  .btn_fale_conosco {
    width: 35%;
  }
}
</style>