<template>
  <header id="inicio">
    <nav class="navbar navbar-expand-md">
      <div class="container-fluid">
        <div class="d-block d-md-none">
          <img class="img_logo" src="../assets/img/logo_2.png" />
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="d-sm-none d-md-block d-none d-sm-block">
            <img class="img_logo" src="../assets/img/logo_2.png" />
          </div>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" href="/">Início</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#faca_parte">Nossas Soluções</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#plataforma_web">Nossa Plataforma</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/#cloud">Marketplace</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/sobre">Sobre</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/contato">Contato</a>
            </li>
          </ul>
          <div class="col-md-2">
            <button type="button" class="btn_fale_conosco" @click="faleConosco()">
              <span class="text_btn_conosco">Fale Conosco</span>
            </button>
            <!-- <button type="button" class="btn_minha_conta">
              <span class="text_btn_conta">Minha Conta</span>
            </button>-->
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();

    function faleConosco() {
      router.push({ name: "contato" });
    }
    return {
      useRouter,
      faleConosco,
    };
  },
};
</script>

<style>
@import "../App.scss";
</style>
<style scoped>
.navbar {
  background-color: #ffffff;
}
a {
  color: #535f74;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  transition: 0.6s;
  font-family: "DMSans-Regular";
}
a:hover {
  color: #64bbfd;
}
/* Botões */
.btn_fale_conosco {
  background-color: #4b89ee;
  color: #fff;
  border: solid 1px;
  border-color: #4b89ee;
  border-radius: 6px;
  margin-right: 10px;
  padding: 4px;
  transition: 0.6s;
  width: 70% !important;
}
.btn_fale_conosco:hover {
  background-color: #3666b4;
  transform: none;
}
.text_btn_conosco {
  color: #fff;
  font-weight: 600;
}
.btn_minha_conta {
  background-color: #fff;
  color: #4b89ee;
  border: solid 1px;
  border-color: #4b89ee;
  border-radius: 6px;
  margin-right: 10px;
  width: 35%;
  padding: 4px;
  transition: 0.6s;
}
.btn_minha_conta:hover {
  background-color: #a7c8fb8a;
  transform: none;
}
.text_btn_conta {
  color: #4b89ee;
  font-weight: 600;
}
.img_logo {
  height: 50px;
  padding-right: 20px;
  margin-left: 100px;
}
html {
  scroll-behavior: smooth;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .img_logo {
    margin-left: 0px;
    padding-right: 30px;
  }
  .btn_fale_conosco {
    width: 100%;
    font-size: 10pt;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .img_logo {
    margin-left: 0;
    padding-right: 0;
    height: 40px;
  }
  .text_btn_conosco {
    height: 40px;
  }
  .btn_fale_conosco {
    padding-right: 0;
    margin: 0;
    width: 100% !important;
  }
  a {
    font-size: 10pt;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .navbar-toggler {
    color: rgb(76, 137, 238);
  }
  .btn_fale_conosco {
    width: 20% !important;
  }
  .img_logo {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    height: 65px;
  }
  a {
    line-height: 1;
  }
}
@media (max-width: 575px) {
  .navbar-toggler {
    color: rgb(76, 137, 238);
  }
  .btn_fale_conosco {
    width: 20% !important;
  }
  .img_logo {
    padding-right: 0px;
    margin-left: 0px;
  }
  a {
    line-height: 1;
  }
  .btn_fale_conosco {
    width: 32% !important;
  }
}
</style>