import axios from 'axios'
import ContatoService from './contato'
const API_ENVS = {
    production: '',
    development: '',
    local: 'https://sifit.com.br/api/'
}

const httpClient = axios.create({
    baseURL: API_ENVS.local
})

export default{
    contato: ContatoService(httpClient)
}