<template>
  <div>
    <top />
    <section id="sobre">
      <div class="container">
        <div class="row row_sobre">
          <!--<div class="col-md-6 div_div">
            <img class="img_logo" src="../assets/img/logo_2.png" />
            <div class="linha"></div>
          </div>-->
          <div class="col-md-12 div_text_sobre">
            <div class="linha_l"></div>
            <div>
              <p class="text_sobre">
                A <strong>SmartSellers</strong> é uma empresa comprometida em
                ajudar seus clientes a entrar e permanecer no mercado de
                e-commerce de maneira diferenciada, com um foco principal na
                qualidade no atendimento e respeito ao cliente.
              </p>
              <p class="text_sobre">
                Sabemos que o mercado de e-commerce está em constante evolução e
                os clientes estão cada vez mais exigentes. É por isso que
                estamos aqui para ajudá-los a ter sucesso em suas empreitadas,
                oferecendo soluções personalizadas que atendam às suas
                necessidades específicas. Com nossa experiência e conhecimento
                em e-commerce, trabalhamos de perto com nossos clientes para
                entender suas necessidades e objetivos, e assim oferecermos
                soluções que atendam suas demandas. Sabemos que cada cliente é
                único e, por isso, personalizamos nossas soluções para atender
                às suas necessidades específicas. Nosso compromisso com a
                qualidade no atendimento é algo que levamos muito a sério.
                Sabemos que uma experiência de compra positiva é fundamental
                para manter os clientes satisfeitos e leais a uma marca.
              </p>
              <p class="text_sobre">
                Por isso, estamos sempre disponíveis para atender nossos
                clientes, oferecendo suporte e orientação em todas as etapas do
                processo de compra. Além disso, o respeito ao cliente é um valor
                fundamental para nós. Acreditamos que a construção de
                relacionamentos baseados na confiança e no respeito é essencial
                para o sucesso a longo prazo de nossos clientes.
              </p>
              <p class="text_sobre">
                A SmartSellers é uma empresa comprometida com o sucesso de seus
                clientes no mercado de Marketplace.Trabalhamos duro para
                oferecer soluções personalizadas e de qualidade, sempre com o
                foco na satisfação e no respeito ao cliente. Se você está
                procurando uma empresa que possa ajudá-lo a ter sucesso no
                mercado de e-commerce, conte conosco. Juntos, podemos alcançar
                grandes resultados.
              </p>
            </div>
            <div class="linha_r"></div>
          </div>
        </div>
        <h2 class="title">Conheça o nosso propósito</h2>
        <div class="video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/yJxlXyjGzUE"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
            class="yt_video"
          ></iframe>
        </div>
        <div class="row row_missao">
          <div class="col-md-6">
            <h1 class="nossa_missao">
              Nossa missão é fazer<span> você crescer</span>
            </h1>
          </div>
          <div class="col-md-6">
            <h6 class="text_sobre">
              Possuímos uma equipe composta por especialistas em ecommerce, com
              anos de experiência em desenvolvimento de software, administração,
              marketing online e gerenciamento de vendas. Trabalhamos em
              estreita colaboração com nossos clientes para entender suas
              necessidades únicas de negócios e oferecer soluções personalizadas
              que atendam às suas necessidades.
              <br />
              <br />
              Temos um compromisso inabalável com a qualidade, oferecendo
              soluções de gerenciamento de ecommerce que são fáceis de usar,
              confiáveis e eficientes. Além disso, estamos sempre procurando
              maneiras de melhorar nossos serviços e fornecer soluções
              inovadoras para nossos clientes.
            </h6>
          </div>
        </div>
      </div>
    </section>
  </div>
  <bottom />
</template>
  <script>
import bottom from "../layout/bottom.vue";
import top from "../layout/top.vue";

export default {
  components: { bottom, top },
  setup() {
    return {
      bottom,
      top,
    };
  },
};
</script>
<style scoped>
#sobre {
  background: linear-gradient(180deg, #ffffff 0%, #eef6ff 100%);
  box-shadow: 0 5px 10px -5px rgba(118, 118, 118, 0.687);
}
.linha_l {
  color: #8ec252;
  width: 1px;
  border: solid 1px;
  height: 500px;
  margin-right: 50px;
  margin-top: -10px;
}
.linha_r {
  color: #8ec252;
  width: 1px;
  border: solid 1px;
  height: 500px;
  margin-left: 50px;
  margin-top: -10px;
}
.div_div {
  display: flex;
}
.img_logo {
  width: 190px;
  height: 85px;
  text-align: center;
  margin-top: 25px;
  margin-left: 15px;
}
.row_sobre {
  margin-top: 100px;
}
.text_sobre {
  font-family: "DMSans-Regular";
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #535f74;
  text-align: justify;
  margin-bottom: 50px;
}
.title {
  font-family: "DMSans-Regular";
  font-weight: 700;
  font-size: 36px;
  line-height: 55px;
  color: #001942;
  margin-top: 35px;
  margin-bottom: 40px;
  text-align: center;
}
h1 {
  font-family: "DMSans-Regular";
  font-weight: 700;
  font-size: 43px;
  line-height: 50px;
  color: #001942;
}
span {
  background: #4b89ee;
}
.video {
  text-align: center;
  width: 100%;
}
.row_missao {
  margin-top: 100px;
}
.div_text_sobre {
  display: flex;
  text-align: justify;
  justify-content: space-between;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .text_sobre {
    line-height: 18px;
    letter-spacing: 0;
  }
  .linha_l,
  .linha_r {
    height: 560px;
  }
  .linha_l {
    height: 560px;
  }
  .title {
    margin-top: 25px;
  }
  h6 {
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 35px;
  }
  .linha_l,
  .linha_r {
    display: none;
  }
  h1 {
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 35px;
  }
  .video {
    text-align: center;
    margin-bottom: -30px;
  }
  h6 {
    margin-bottom: 30px;
    text-align: center;
  }
  .div_text_sobre {
    margin-top: -50px;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  h1 {
    font-size: 35px;
  }
  .linha_l,
  .linha_r {
    display: none;
  }
  .title {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .yt_video {
    width: 400px;
  }
  .video {
    text-align: center;
    margin-bottom: -50px;
  }
  .nossa_missao {
    font-size: 30px;
  }
  h6 {
    margin-bottom: 50px;
    text-align: center;
  }
  .div_text_sobre {
    margin-top: -60px;
  }
}
@media (max-width: 575px) {
  h1 {
    font-size: 35px;
  }
  .linha_l,
  .linha_r {
    display: none;
  }
  .title {
    font-size: 30px;
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .yt_video {
    width: 400px;
    width: 100%;
  }
  .video {
    text-align: center;
    margin-bottom: -50px;
  }
  .nossa_missao {
    font-size: 30px;
  }
  h6 {
    margin-bottom: 50px;
    text-align: center;
  }
  .div_text_sobre {
    margin-top: -60px;
  }
}
</style>